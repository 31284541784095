<!--
File:                   PlaylistView.vue
Author:                 Vincent Audergon (https://github.com/AudergonV)
Description:            Playlist view page
                        Not implemented yet, will allow to create predefined playlists that the users
                        will be able to download on there Sunae device
Version:                1.0
Last modification date: 31.08.22
-->


<template>
  <div class="home">
    <h1>Area under construction...</h1>
    <img width="10%" src="https://de.rakko.tools/image/loading_rakko.gif" />  </div>
</template>

<script>
export default {

}
</script>
