<template>
    <div class="firmwareTag">
        <v-chip v-if="tag == 'beta'"
            class="ma-2"
            color="red"
            outlined
        >
            🚧 Bêta
        </v-chip>
        <v-chip v-if="tag == 'debug'"
            class="ma-2"
            color="purple"
            outlined
        >
            🐛 Debug
        </v-chip>
        <v-chip v-if="tag == 'prerelease'"
            class="ma-2"
            color="orange"
            outlined
        >
            ⏲️ Pre-release
        </v-chip>
        <v-chip v-if="tag == 'bugfix'"
            class="ma-2"
            color="blue"
            outlined
        >
            🔧 Bug fix
        </v-chip>
        <v-chip v-if="tag == 'release'"
            class="ma-2"
            color="success"
            outlined
        >
            🚀 Release
        </v-chip>
        <v-chip v-if="tag == 'test'"
            class="ma-2"
            color="green"
            outlined
        >
            🧪 Test
        </v-chip>
    </div>
</template>

<script>
export default {
  props: ['tag']
}
</script>
