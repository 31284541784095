/****************************************************************************
 * @file index.js
 * @author Vincent Audergon <https://github.com/AudergonV>
 *
 * @brief Authentication utils
 *
 * @modifdate 31.08.22
 * @version 1.0.0
 ***************************************************************************/

const ACCESS_TOKEN_KEY = 'access_token';
import router from "@/router";
import api from "@/api";
import store from '@/store';

/**
 * Middelware that checks if the user is connected (valid token available)
 * @param {*} to 
 * @param {*} from 
 * @param {*} next 
 */
export async function requireAuth(to, from, next) {
    if (!isLoggedIn()) {
        next({
            path: '/login',
            query: { redirect: to.fullPath }
        });
    } else {
        let response = (await api.checkSession());
        response = JSON.parse(response);
        console.log(response);
        if (response.message) {
            store.commit("setConnected", true);
            next();
        } else {
            next({
                path: '/login',
                query: { redirect: to.fullPath }
            });
        }
    }
}

export function login(accessToken) {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    router.push('/');
}

export function getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
}

export function logout() {
    clearAccessToken();
    store.commit("setConnected", false);
    router.push('/login');
}

function clearAccessToken() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
}

export function isLoggedIn() {
    return getAccessToken() !== null;
}