<!--
File:                   HomeView.vue
Author:                 Vincent Audergon (https://github.com/AudergonV)
Description:            Home view page
                        Not implemented yet, will contain a dashboard (statistics, etc.)
Version:                1.0
Last modification date: 31.08.22
-->

<template>
  <div class="home">
    <h1>Dashboard</h1>
  </div>
</template>

<script>
export default {

}
</script>
