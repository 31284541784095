<!--
File:                   EditDrawingVue.vue
Author:                 Vincent Audergon (https://github.com/AudergonV)
Description:            Edit drawing view page
                        Allows to edit a drawing
Version:                1.0
Last modification date: 31.08.22
-->

<template>
  <div class="home">
    <h1>Editer le dessin</h1>
    <div v-if="svg">
        <h2>Options</h2>
        <v-text-field
            class="mt-4"
            v-model="name"
            label="Nom du dessin"
            clearable
          ></v-text-field>
        <v-checkbox
            v-model="eraseBefore"
            label="Toujours effacer avant"
            color="accent"
        ></v-checkbox>
        <v-slider
            label="Epaisseur du trait"
            max="3"
            min="0.05"
            step="0.05"
            v-model="strokeWidth"
            thumb-label="always"
            thumb-color="accent"
            >
        </v-slider>
        <h2>Aperçus</h2>
        <v-row>
            <v-col 
                cols="12"              
                xl="3"
                lg="3"
                md="6"
                sm="12"
                xs="12"
            > 
                <v-card elevation="2">
                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg"><polyline :points="svg" fill="none" stroke="black" :stroke-width="strokeWidth"/></svg>
                </v-card>
            </v-col>
            <v-col   
                cols="12"              
                xl="2"
                lg="2"
                md="4"
                sm="6"
                xs="6"> 
                <v-card elevation="2">
                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg"><polyline :points="svg" fill="none" stroke="black" :stroke-width="strokeWidth"/></svg>
                </v-card>
            </v-col>
            <v-col     
                cols="12"                          
                xl="1"
                lg="1"
                md="2"
                sm="3"
                xs="3"> 
                <v-card elevation="2">
                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg"><polyline :points="svg" fill="none" stroke="black" :stroke-width="strokeWidth"/></svg>
                </v-card>
            </v-col>
            <v-col 
                cols="12"              
                xl="3"
                lg="3"
                md="6"
                sm="12"
                xs="12"
            > 
                <v-card elevation="2">
                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg"><polyline :points="svg" fill="none" stroke="#adcab8" :stroke-width="strokeWidth"/></svg>
                </v-card>
            </v-col>
            <v-col   
                cols="12"                            
                xl="2"
                lg="2"
                md="4"
                sm="6"
                xs="6"> 
                <v-card elevation="2">
                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg"><polyline :points="svg" fill="none" stroke="#adcab8" :stroke-width="strokeWidth"/></svg>
                </v-card>
            </v-col>
            <v-col     
                cols="12"                          
                xl="1"
                lg="1"
                md="2"
                sm="3"
                xs="3"> 
                <v-card elevation="2">
                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg"><polyline :points="svg" fill="none" stroke="#adcab8" :stroke-width="strokeWidth"/></svg>
                </v-card>
            </v-col>
        </v-row>
        <img hidden id="thumbnail" width="350" height="350"/>
        <img hidden id="thumbnailActive" width="350" height="350"/>
        <canvas hidden id="mycanvas" width="350" height="350"></canvas>
        <!-- Device model -->
        <div>
            <h2 class="mt-5">Disponibilité</h2>
            <v-row style="flex-wrap: nowrap" class="overflow-x-auto">
                <v-col
                    cols="4" md="2"
                    v-for="model in models" :key="model.uuid"
                    >
                    <v-checkbox
                        v-model="model.isSelected"
                        :label="model.name"
                        color="accent"
                    ></v-checkbox>
                    <!-- Model mode -->
                    <div v-if="model.isSelected">
                        <v-row v-for="mode in model.modes" :key="mode.uuid">
                            <v-checkbox
                                v-model="mode.isSelected"
                                :label="mode.name"
                                color="accent"
                            ></v-checkbox>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </div>
        <!-- Subit button -->
        <v-btn class="mt-4" color="accent" @click="editDrawing">Valider</v-btn>
    </div>
    <v-overlay :value="loading">
    <v-progress-circular
        :size="70"
        :width="7"
        color="accent"
        indeterminate
        ></v-progress-circular>
    </v-overlay>

  </div>
</template>

<script>
import api from "@/api";
import router from "@/router";

export default {
    data () {
      return {
          svg:"",
          svgActive: "",
          strokeWidth: 1,
          eraseBefore: true,
          name:"",
          file: undefined,
          loading: false,
          uuid: 0,
          models: [],
      }
    },
    async created() {
        // Start loading data
        this.loading = true;
        // Models
        this.models = await api.getDeviceModels();
        // Drawing
        let drawing = await api.getDrawing(this.$route.params.uuid);
        this.svg = drawing.svg.split("points=\"")[1].split("\"")[0];
        this.strokeWidth = Number(drawing.svg.split("stroke-width=\"")[1].split("\"")[0]);
        this.svgActive = this.svg;
        this.name = drawing.name;
        this.eraseBefore = drawing.eraseBefore;
        this.uuid = drawing.uuid;
        const modes = await api.getDeviceModelsModes();
        this.models.forEach((model, index) => {
            let modelSelected = JSON.parse(drawing.disponibilities).reduce((acc, cur) => acc || cur == model.uuid, false);
            this.$set(this.models, index, { 
                ...model, 
                isSelected: modelSelected,
                modes: modes.filter((v) => v.model == model.uuid).map(it => { 
                    it['isSelected'] = modelSelected && drawing.drawingModes.reduce((acc, cur) => acc || cur.uuid == it.uuid, false); 
                    return it; 
                })
            });
        });
        // End loading data
        this.loading = false;
    },
    methods: {
        async editDrawing() {
            // Get models and modes
            const disponibilities = [];
            const modelModes = [];
            for(const model of this.models){
                if(model.isSelected) {
                    let hasSelected = model.modes.reduce((acc, cur) => acc || cur.isSelected, false);
                    if (model.modes.length !== 0 && !hasSelected) {
                        alert(`${model.name} n'a pas de drawing-mode défini`);
                        return;
                    }
                    disponibilities.push(model.uuid)
                    for (const mode of model.modes) {
                        if (mode.isSelected) modelModes.push(mode.uuid)
                    }
                }
            }
            this.loading = true;
            // TODO : fix this for Firefox
            let svg = `<?xml version="1.0" encoding="UTF-8" standalone="no"?><svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <polyline points="${this.svg}" fill="none" stroke="black" stroke-width="${this.strokeWidth}"/>
            </svg>`;
            let svgActive = `<?xml version="1.0" encoding="UTF-8" standalone="no"?><svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <polyline points="${this.svg}" fill="none" stroke="#adcab2" stroke-width="${this.strokeWidth}"/>
            </svg>`;
            let canvas = document.getElementById("mycanvas");
            let ctx = canvas.getContext('2d');
            let img = document.getElementById( "thumbnail" );
            let imgActive = document.getElementById( "thumbnailActive" );
            let png = "";
            let pngActive = "";
            await new Promise(resolve => {
                img.setAttribute( "src", "data:image/svg+xml;base64," +  btoa(svg)  );
                img.onload = async () => {
                    ctx.drawImage( img, 0, 0);
                    resolve(canvas.toDataURL("image/png"));
                };
            }).then(res => {
                png = res;
            });

            ctx.clearRect(0, 0, canvas.width, canvas.height);
            await new Promise(resolve => {
                imgActive.setAttribute( "src", "data:image/svg+xml;base64," +  btoa(svgActive)  );
                imgActive.onload = async () => {
                    ctx.drawImage( imgActive, 0, 0);
                    resolve(canvas.toDataURL("image/png"));
                };
            }).then(res => {
                pngActive = res;
            });
            // Send the request to edit the drawing
            console.log(await api.editDrawing(this.uuid, 
                                              svg, 
                                              this.name, 
                                              this.eraseBefore, 
                                              png, 
                                              pngActive, 
                                              disponibilities,
                                              modelModes));
            this.loading = false;
            router.push('/drawings');
        }
    }
}
</script>
