<!--
File:                   DrawingsView.vue
Author:                 Vincent Audergon (https://github.com/AudergonV)
Description:            Drawings view page
                        Allows to delete, export, modify drawings
                        Shows a list of all the drawings
Version:                1.0
Last modification date: 31.08.22
-->

<template>
  <div class="drawings_export">
    <h1>Dessins</h1>

    <!-- Actions -->
    <v-card
        class="elevation-1">
        <v-card-text>
            <v-label>Actions</v-label>
            <v-row class="mt-1">
                <v-col cols="12">
                    <v-btn
                        class="accent"
                        dark
                        small
                        to="/drawings/new"
                    >
                    <v-icon dark>
                        mdi-plus
                    </v-icon>
                        Ajouter un dessin
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class="mt-3 mb-3"></v-divider>
            <v-label>Actions groupées {{selected.length > 0 ? `| ${selected.length} dessin(s) sélectionné(s)` : ''}}</v-label>
            <v-row class="mt-1">
                <v-col cols="12">
                    <v-btn @click="exportDrawings" :disabled="selected.length == 0" small class="mr-3" color="primary" v-bind="attrs" v-on="on">
                        ⬆️ Exporter pour une carte SD
                    </v-btn>
                    <v-btn @click="regeneratePngs" :disabled="selected.length == 0" small class="mr-3" color="success" v-bind="attrs" v-on="on">
                        ♻️ Regénérer les png
                    </v-btn>
                    <v-btn @click="regenerateGcodes" :disabled="selected.length == 0" small class="mr-3" color="success" v-bind="attrs" v-on="on">
                        📄 Regénérer les GCodes
                    </v-btn>
                    <v-btn @click="hideSelected" :disabled="selected.length == 0" small color="error" v-bind="attrs" v-on="on">
                        ❌ Supprimer
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>

    <!-- Drawings -->
    <v-data-table
        v-model="selected"
        :items="drawings"
        :headers="headers"
        item-key="uuid"
        show-select
        class="elevation-1 mt-3"
        :loading="loading"
        loading-text="Chargement des dessins..."
        id="drawingsList"
        :items-per-page="-1"
        hide-default-footer
    >
    <template v-slot:item.png="{ item }">
        <v-img class="mt-1 mb-1" :src="item.png" height="75" width="75"></v-img>
        <v-img class="mt-1 mb-1" :src="item.pngActive" height="75" width="75"></v-img>
    </template>
    <template v-slot:item.creationDate="{ item }">
        {{ moment(item.creationDate).format("DD/MM/YYYY") }}
    </template>
    <template v-slot:item.gcodeAvailable="{ item }">
        <v-chip v-if="item.gcodeAvailable" color="success" outlined>Oui</v-chip>
        <v-chip v-else color="error" outlined>Non</v-chip>
    </template>
    <template v-slot:item.eraseBefore="{ item }">
        <v-chip v-if="item.eraseBefore" color="success" outlined>Oui</v-chip>
        <v-chip v-else color="error" outlined>Non</v-chip>
    </template>
    <template v-slot:item.disponibilities="{ item }">
        <v-chip
                @click="toggleDisponibility(item, model)"
            v-for="model in models"
            :key="model.uuid"
            :color="item.disponibilities.includes(model) ? 'success' : 'error'"
            outlined
        >{{ model.name }}<span v-if="item.disponibilities.includes(model)">✅</span><span v-else>❌</span></v-chip>
    </template>
    <template v-slot:item.actions="{ item }">
         <v-btn block small  @click="item.downloads++" :href="`https://management.sunae.ch/api.php/drawing/download?uuid=${item.uuid}`" color="primary" outlined v-bind="attrs" v-on="on">
            ⬇️ Télécharger
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn block outlined small color="warning" v-bind="attrs" v-on="on" :to="`drawings/edit/${item.uuid}`">
            🖊️ Modifier
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn block small color="error" v-bind="attrs" v-on="on" @click="hide(item.uuid)">
            ❌ Supprimer
        </v-btn>
    </template>
    {{ drawings}}

    </v-data-table>

    <!-- Loading -->
    <v-overlay :value="waiting">
        <v-row>
            <v-col cols="12" class="text-center">
                <v-progress-circular
                :size="70"
                :width="7"
                color="accent"
                indeterminate
                ></v-progress-circular>
            </v-col>
            <v-col cols="12" class="text-center">
                <span class="title">
                    {{ waitingMessage }}
                </span>
            </v-col>
            <v-col cols="12" class="text-center">
                <span class="title">
                    {{ waitingMessage2 }}
                </span>
            </v-col>
        </v-row>
    </v-overlay>

    <!-- Used for png generation -->
    <img hidden id="thumbnail" width="350" height="350"/>
    <img hidden id="thumbnailActive" width="350" height="350"/>
    <canvas hidden id="mycanvas" width="350" height="350"></canvas>
  </div>
</template>

<script>
import api from "@/api";
import moment from "moment";

export default {
    data() {
        return {
            moment,
            drawings: [],
            selected: [],
            loading: false,
            waiting: false,
            waitingMessage: "",
            waitingMessage2: "",
            headers: [
                { text: 'Aperçus', value: 'png', sortable: false },
                { text: 'Nom', value: 'name'},
                { text: 'ID', value: 'uuid'},
                { text: 'Gcode', value:'gcodeAvailable'},
                { text: 'Date de publication', value: 'creationDate'},
                { text: 'Effacement avant', value: 'eraseBefore'},
                { text: 'Téléchargements', value: 'downloads'},
                { text: 'Disponibilité', value: 'disponibilities', sortable: true},
                { text: 'Actions', value:'actions', sortable: false},
            ],
            models: []
        }
    },
    created() {
        this.loadData();
    },
    methods: {
        async loadData() {
            // Load models
            this.models = await api.getDeviceModels();
            // Load drawings
            await this.loadDrawings();
        },
        async loadDrawings() {
            this.loading = true;
            let d = [];
            let from = 0;
            let to = 10;
             do {
                d = await api.getAllDrawings(from,to);
                for(let drawing of d){
                    const disponibilities = [];
                    for(const dispo of JSON.parse(drawing.disponibilities)){
                        for(const model of this.models){
                            if(model.uuid == dispo){
                                disponibilities.push(model);
                                break;
                            }
                        }
                    }
                    drawing.disponibilities = disponibilities;
                }
                from += 10;
                to += 10;
                this.drawings.push(...d);
             } while(d.length >= 10);
            this.loading = false;
        }, 
        async hide(uuid)  {
            await api.hideDrawing(uuid);
            this.drawings = this.drawings.filter(drawing => drawing.uuid !== uuid);
        },
        async toggleDisponibility(drawing, model) {
            if(drawing.disponibilities.includes(model)){
                drawing.disponibilities = drawing.disponibilities.filter(m => m.uuid !== model.uuid);
            } else {
                drawing.disponibilities.push(model);
            }
            let test = await api.patchDisponibilities(drawing.uuid, drawing.disponibilities.map(m => m.uuid));
            console.log(test);
        },
        async hideSelected() {
            this.waitingMessage = "Traitement en cours... ";
            this.waiting = true;
            let total = 1;
            for (let drawing of this.selected) {
                this.waitingMessage2 = `Suppression de ${drawing.name} (${total}/${this.selected.length})`;
                await this.hide(drawing.uuid);
                total++;
            }
            this.waiting = false;
            this.waitingMessage = "";
            this.waitingMessage2 = "";
        },
        async regeneratePngs() {
            this.waitingMessage = "Traitement en cours... ";
            this.waiting = true;
            let total = 1;
            for (let drawing of this.selected) {
                this.waitingMessage2 = `Génération des pngs pour ${drawing.name} (${total}/${this.selected.length})`;
                await this.generatePngs(drawing.uuid);
                total++;
            }
            this.waiting = false;
            this.waitingMessage = "";
            this.waitingMessage2 = "";
            // window.location.reload();
        },
        async generatePngs(uuid) {
            let svg = (await (api.getDrawing(uuid))).svg;
            let svgActive = svg.replace("black", "#adcab2");
            let canvas = document.getElementById("mycanvas");
            let ctx = canvas.getContext('2d');
            let img = document.getElementById( "thumbnail" );
            let imgActive = document.getElementById( "thumbnailActive" );
            let png = "";
            let pngActive = "";
            await new Promise(resolve => {
                img.setAttribute( "src", "data:image/svg+xml;base64," +  btoa(svg)  );
                img.onload = async () => {
                    ctx.drawImage( img, 0, 0);
                    resolve(canvas.toDataURL("image/png"));
                };
            }).then(res => {
                png = res;
            });

            ctx.clearRect(0, 0, canvas.width, canvas.height);
            await new Promise(resolve => {
                imgActive.setAttribute( "src", "data:image/svg+xml;base64," +  btoa(svgActive)  );
                imgActive.onload = async () => {
                    ctx.drawImage( imgActive, 0, 0);
                    resolve(canvas.toDataURL("image/png"));
                };
            }).then(res => {
                pngActive = res;
            });
            await api.updateDrawingPng(
                uuid,
                png,
                pngActive
            );
            ctx.clearRect(0, 0, canvas.width, canvas.height);
        },
        async regenerateGcodes() {
            this.waitingMessage = "Traitement en cours... ";
            this.waitingMessage2 = `Cette opération peut prendre un certain temps, veuillez patienter.`;
            this.waiting = true;
            let ids = [];
            for (let drawing of this.selected) {
                ids.push(drawing.uuid);
            }
            await api.regenerateGcodes(ids);
            this.waiting = false;
            this.waitingMessage = "";
            this.waitingMessage2 = "";
            // window.location.reload();
        },
        async exportDrawings() {
            this.waitingMessage = "Traitement en cours... ";
            this.waitingMessage2 = `Cette opération peut prendre un certain temps, veuillez patienter.`;
            this.waiting = true;
            let ids = [];
            for (let drawing of this.selected) {
                ids.push(drawing.uuid);
            }
            await api.exportDrawings(ids);
            window.open('https://management.sunae.ch/sd.zip', '_blank');
            this.waiting = false;
            this.waitingMessage = "";
            this.waitingMessage2 = "";
        }
    }
}
</script>

