/****************************************************************************
 * @file main.js
 * @author Vincent Audergon <https://github.com/AudergonV>
 *
 * @brief Main entrance of the web application
 *
 * @modifdate 31.08.22
 * @version 1.0.0
 ***************************************************************************/

import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import axios from 'axios'

Vue.config.productionTip = false;

axios.defaults.withCredentials = true;

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
