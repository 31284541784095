/****************************************************************************
 * @file index.js
 * @author Vincent Audergon <https://github.com/AudergonV>
 *
 * @brief Vuex store
 *
 * @modifdate 31.08.22
 * @version 1.0.0
 ***************************************************************************/

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    connected: false //indicates if the user is connected to the server (token valid)
  },
  getters: {
    isConnected(state) {
      return state.connected;
    }
  },
  mutations: {
    setConnected(state, connected) {
      state.connected = connected;
    }
  },
  actions: {
  },
  modules: {
  }
})
